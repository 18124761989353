.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.react-horizontal-scrolling-menu--inner-wrapper{
    overflow-y: unset !important;
}

.react-horizontal-scrolling-menu--arrow-left{
    display: flex;
    align-items: center;
}

.react-horizontal-scrolling-menu--arrow-right{
    display: flex;
    align-items: center;
}