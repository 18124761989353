.sale_button {
    background-image: linear-gradient(to right, #FFD600, #FF8000);
    width: 100%;
    border: none !important;
    color: white !important;
}

@media (min-width:1281px) {
    .sale_block__new_price {
        padding-right: 5px;
        font-size: 18px;
        font-weight: 700;
    }
    .card_image_block {
        display: flex;
        justify-content: center;
    }
    .card_image {
        width: 50% !important;
    }
}
.market_button {
    background-image: linear-gradient(to right, #9266cc, #9266cc);
    width: 100%;
    border: none !important;
    color: white !important;
}

.return_to_button {
   /*  background-image: linear-gradient(to right, #FFD600, #FFD600);
    width: 100%;
    border: none !important;
    color: white !important;
 */

    background:   linear-gradient(to bottom, rgba(177, 51, 255, 0.8)    , rgba(177, 51, 255, 0.8)    ),url("./rest.jpg")  ;
    border: 0;
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    width: 100%;
    height: 80px;
    font-size: 18px !important;
    font-family: "Nunito-Extra-Bold" !important;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);
    text-transform: none !important; 
    color: white !important;
}

.yr{
    background:   linear-gradient(to bottom, rgba(255, 172, 51, 0.8)    , rgba(255, 172, 51, 0.8)),url("./market.jpg")  ;
        border: 0;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        width: 100%;
        height: 80px;
        font-size: 18px !important;
        font-family: "Nunito-Extra-Bold" !important;
        border-radius: 8px;
        box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
        text-transform: none !important; 
        color: white !important;    
}


