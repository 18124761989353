.MuiInput-underline:before {
    border: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
}

.MuiInput-underline:after {
    border: none !important;
}