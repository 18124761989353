.sale_block {
    display: flex;
}

@media (min-width:320px) {
    .sale_block__new_price {
        padding-right: 5px;
        font-size: 15px;
        font-weight: 700;
        color: #9266CC;
    }
    .card_image_detail {
        width: 100% !important;
        
    }
    .sale_block__old_price {
        padding-right: 5px;
        color: #B3B3B3;
        font-weight: 700;
        font-size: 14px;
        text-decoration: line-through;
    }
    .price_block.del {
        position: relative;
        color: #1b1a1a;
        text-decoration: none;
        font-size: 20px;
    }
    .price_block.del:before {
        content: "";
        border-bottom: 2px solid #fb0808;
        font-size: 14px;
        position: absolute;
        width: 100%;
        height: 50%;
        transform: rotate(12deg);
    }
    .order_button {
        width: 100%;
        font-size: 9px !important;
    }
    .price_block {
        /*  background-color: #F0EFEF; */
        width: 100%;
        padding: 10px;
        border-radius: 12px;
        font-size: 15px;
    }
    .price_block_for_sale {
        /*  background-color: #F0EFEF; */
        width: 100%;
        /* padding: 10px; */
        border-radius: 12px;
        display: flex;
        /*     justify-content: center; */
        align-items: center;
    }
    .block__old_price {
        padding-right: 5px;
        font-size: 15px;
        font-weight: 700;
        color: #9266CC;
        /* background: lightgray; */
        padding-top: 10px;
        padding-bottom: 10px;

    /*     display: flex; */
      
     
        /* justify-content: center; */
    }
    .block_count {
        padding-right: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #9266CC;
        display: flex;
        justify-content: center;
    }
    .add_button_pic {
        color: #FAA241 !important;
        height: 100% !important;
        /*  font-size: 15px !important; */
    }
    .add_button {
        padding: 5px !important;
    }
    .add_button_1 {
        width: 100%;
        display: flex !important;
        justify-content: flex-end !important;
    }
    .price_name {
        font-size: 15px !important;
        font-weight: 700 !important;
    }
    .product_card {
        padding-top: 2px !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

@media (min-width:480px) {
    .sale_block__new_price {
        padding-right: 5px;
        font-size: 18px;
        font-weight: 700;
    }
    .sale_block__old_price {
      
        padding-right: 5px;
        color: #B3B3B3;
        font-weight: 700;
        font-size: 12px;
        text-decoration: line-through;
    }
    .block__old_price {

        padding-right: 5px;
        font-size: 18px;
        font-weight: 700;
        color: #9266CC;
        display: flex;
        /* justify-content: center; */
    }
    /* .del {
        position: relative;
        color: #1b1a1a;
        text-decoration: none;
    }
    .del:before {
        content: "";
        border-bottom: 2px solid #fb0808;
        position: absolute;
        width: 100%;
        height: 50%;
        transform: rotate(12deg);
    } */
    .order_button {
        width: 100%;
        font-size: 15px !important;
    }
    .add_button {
        padding: 5px !important;
    }
    .add_button_1 {
        width: 100%;
        display: flex !important;
        justify-content: flex-end !important;
    }
}

@media (min-width:1281px) {
    .sale_block__new_price {
        padding-right: 5px;
        font-size: 18px;
        font-weight: 700;
    }
    .card_image_block {
        display: flex;
        justify-content: center;
    }
    .card_image {
        width: 50% !important;
    }
    .card_image_detail {
        width: 60% !important;
    }
    .sale_block__old_price {
        padding-right: 5px;
        color: #B3B3B3;
        font-weight: 700;
        font-size: 15px;
        text-decoration: line-through;
    }
    .order_button {
        width: 100%;
        font-size: 15px !important;
    }
    .price_block {
        /*  background-color: #F0EFEF; */
        width: 100%;
        padding: 10px;
        border-radius: 12px;
    }
    .price_block_for_sale {
        /*  background-color: #F0EFEF; */
        width: 100%;
        /* padding: 10px; */
        border-radius: 12px;
        display: flex;
        /*  justify-content: center; */
        align-items: center;
    }
    .block__old_price {
        padding-right: 5px;
        font-size: 18px;
        font-weight: 700;
        color: #9266CC;
        display: flex;
        /* justify-content: center; */
    }
    .add_button_pic {
        color: #FAA241 !important;
        height: 100% !important;
        /* font-size: 34px !important; */
    }
    .add_button {
        padding: 5px !important;
    }
    .add_button_1 {
        width: 100%;
        display: flex !important;
        justify-content: flex-end !important;
    }
    .infinite-scroll-component {
        overflow: hidden !important;
    }
}

[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  [type=radio]:checked + img {
    outline: 2px solid #FAA241;
  }

  /* [type=radio]:default + img {
    outline: 2px solid #f00;
  } */

  .ok_button{
    background-color: #ff8601  !important;
    color: white !important;
    border: none !important;
    width: 100%;
  }

  .price_name_deleted{
    text-decoration: line-through;
  }