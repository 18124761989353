@import 'mixins';

.c-YMap {
  &-PointItem {
    @include text-stroke(4, rgba(255, 255, 255, .1));
    position: absolute;
    top: 50%;
    left: 50%;
    color: dodgerblue;
    z-index: 1;
  }

  &-PointText {
    font-size: 18px;
    text-align: center;
    transform: translateX(-50%);
  }

  &-Point {
    font-size: 48px;
    transform: translateX(-50%) translateY(-100%);
  }
}

.c-MapInput {
  &-Input {
    width: 100%;
  }
}