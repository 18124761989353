#outlined-read-only-input.Mui-disabled {
    background: white !important;
    color: #d5d3d3 !important
}

.css-kleyjz-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
    background: white !important;
    color: #d5d3d3 !important
}


#filled-disabled.Mui-disabled {
    background: white !important;
    color: #d5d3d3 !important
}
