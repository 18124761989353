.card2:first-child {
    margin-left: 2px;
    margin-bottom: 2px;
}

.card2 {
    margin-right: 10px;
}

@media (min-width:320px) {
    .card1 {
        width: 100% !important;
        height: 100px !important;
    }

    .card2 {
        width: 100% !important;
        /*    height: 170px !important; */
        height: 12rem !important;
    }

    .tag_name {
        font-size: 15px;
    }

    .card_text {
        font-size: 10px;
    }
    .card_content {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        min-height: 40%;
        align-items: flex-end
    }
    /* .card_name{
        font-size: 13px !important;
    } */

    .card_content_full{
        height: 50%;
    }
    
}

@media (min-width:480px) {
    .card1 {
        width: 250px !important;
        height: 100px !important;
    }

    .card2 {
        width: 250px !important;
        height: 170px !important;
    }

    .tag_name {
        font-size: 15px;
    }

    .card_name {
        font-size: 15px !important;
    }
}

@media (min-width:1281px) {
    .card1 {
        width: 100% !important;
        height: 150px !important;
    }

    .card2 {
        width: 100% !important;
        height: 220px !important;

    }

    .card_name {
        font-weight: 800;
    }

    .tag_name {
        font-size: 30px;
    }

    .card_name {
        font-size: 20px !important;
    }

    .card_content {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
      /*   min-height: 40%; */
        align-items: flex-end
    }
    .card_content_full{
        height: 0px;
    }
   
}